.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* 在你的全局CSS文件中 */
.tr-hover:hover {
  background-color: #ff686850;
}


.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 等分三栏 */
  grid-gap: 15px; /* 栏与栏之间的间隔 */
  padding: 0px; /* 容器的内边距 */
  margin-top: 20PX;
  box-Sizing: border-box;
  max-Width: 1320px;
  width: 100%; 
  height: auto;
  margin-Right: auto;
  margin-Left: auto;
  background-Repeat: no-repeat;
  background-Size: cover;
  background-Position: center;
}

.column {
  background-color: rgba(0, 0, 0, 0.171); /* 半透明背景 */
  border-radius: 20px; /* 圆角 */
  backdrop-filter: blur(80px); /* 高斯模糊效果 */
  padding: 20px; /* 栏目内部的内边距 */
  text-align: center; /* 文字居中 */
  color: aliceblue;
}

@media (max-width: 600px) {
  .container {
      grid-template-columns: 1fr; /* 在小屏幕上，每栏占满整行 */
  }
}

